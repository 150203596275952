import * as React from 'react';

import './MatchStream.scss'
import { TickerMatchState, TickerMatch } from '../../models';
import { Helmet } from 'react-helmet';
import { StreamSetScore } from './StreamSetScore';

interface Props {
  match: TickerMatch
  matchState: TickerMatchState
  size?: string
}

const MatchStream = (props: Props) => {

    const {
      match,
      matchState,
      size
    } = props

    return <div className="MatchStream">
      <Helmet>
        <title>{match.teamDescription1} - {match.teamDescription2}</title>
      </Helmet>
      <StreamSetScore matchState={matchState} match={match} size={size} />
    </div>

}

export default MatchStream
