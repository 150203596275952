import * as React from 'react';
import { TickerMatchState, Status, TickerMatchSeries, TickerMatch, TickerMatchStats } from '../../models';
import './MatchDetailBody.scss';
import { bindActionCreators, Dispatch } from 'redux';
import { setMatchDetailView, MatchDetailView } from './detail-view/reducer';
import { connect } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { MatchDetailViewInstance } from './MatchDetail';
const Swipeable: any = SwipeableViews

interface OwnProps {
  match: TickerMatch
  matchState: TickerMatchState
  matchSeries: TickerMatchSeries
  matchStats: TickerMatchStats
  status: Status
  matchDetailView: MatchDetailView
  matchDetailViews: MatchDetailViewInstance[]
  isDesktopView: boolean,
  setUpdateHeight: any
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  setMatchDetailView
}, dispatch)

type Props = ReturnType<typeof mapDispatchToProps> & OwnProps

const MatchDetailBody = (props: Props) => {

  const {
    matchDetailView,
    matchDetailViews,
    setMatchDetailView,
    isDesktopView,
    setUpdateHeight
  } = props

  const handleChangeIndex = (idx: number) => {
    const newView = matchDetailViews[idx]
    return setMatchDetailView(newView.key)
  }
  const activeView = matchDetailViews.find(m => m.key === matchDetailView) || matchDetailViews[0]
  const index = matchDetailViews.indexOf(activeView)
  const viewElements = matchDetailViews.map(m => m.element)

  return isDesktopView 
    ? <Swipeable
        action={(node: any) => setUpdateHeight(node.updateHeight)}
        animateHeight resistance
        onChangeIndex={handleChangeIndex}
        index={index}>
        {viewElements}
      </Swipeable>
    : <div className="MatchDetailBody mobile">
        <Swipeable
          action={(node: any) => setUpdateHeight(node.updateHeight)}
          animateHeight resistance
          style={{ "paddingBottom": "50px", "flex": "1 0 auto", "display": "flex", "flexDirection": "column" }}
          containerStyle={{ "flex": "1 0 auto", "display": "flex" }}
          onChangeIndex={handleChangeIndex}
          index={index}>
          {viewElements}
        </Swipeable>
      </div>

}

export default connect(null, mapDispatchToProps)(MatchDetailBody)
