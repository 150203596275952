import * as React from 'react'
import './footer.scss';

interface Props {
  copyright?: string
  url?: string
}

export default (props: Props) => {
  return (
    <div className="App-footer">
      {props.url && <a target="_blank" rel="noopener noreferrer" href={props.url}>&copy; {props.copyright}</a>}
      <div>
        <img src="/images/ticker-logo.svg" alt="SAMS Ticker"/>
        <div className="version-tag">v6.4.0</div>
      </div>
    </div>
  )
}
