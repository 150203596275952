import React from "react"
import { TickerMatch, TickerMatchState } from "../../models"
import "./StreamSetScore.scss"
import { ReactComponent as SetBallIcon } from '../../images/ticker-icons/set-ball.svg';

interface Props {
  matchState: TickerMatchState
  match: TickerMatch
  size?: string
}

export const StreamSetScore = React.memo((props: Props) => {

  const { matchState, match, size } = props
  const isSmall = size === 'small'
  const isShort = size === 'short'

  if (!matchState) {
    return <div className={"StreamSetScore" + (isSmall ? " small" : "") + (isShort ? " short" : "")}>
      <div className="TeamNames">
        <div className="TeamName">
          <div className="serving"></div>
          {isSmall ? match.team1.clubCode : (isShort ? match.team1.shortName : match.team1.name)}
        </div>
        <div className="TeamName">
          <div className="serving"></div>
          {isSmall ? match.team2.clubCode : (isShort ? match.team2.shortName : match.team2.name)}
        </div>
      </div>
      <div className="ScoreContainer">
        <div className="Scores">
          <div className="Score">
            0
          </div>
          <div className="Score">
            0
          </div>
        </div>
        <div className="Scores">
          <div className="Score">
            0
          </div>
          <div className="Score">
            0
          </div>
        </div>
      </div>     
    </div>
  }

  let isTeam1Serving = matchState.serving === 'team1' ? true : false

  const setPoints = matchState.setPoints
  const currentSet = matchState.matchSets[matchState.matchSets.length -1]
  const { setScore } = currentSet

  return <div className={"StreamSetScore" + (isSmall ? " small" : "") + (isShort ? " short" : "")}>
      <div className="TeamNames">
        <div className="TeamName">
          <div className="serving">{isTeam1Serving && <SetBallIcon></SetBallIcon>}</div>
          {isSmall ? match.team1.clubCode : (isShort ? match.team1.shortName : match.team1.name)}
        </div>
        <div className="TeamName">
          <div className="serving">{!isTeam1Serving && <SetBallIcon></SetBallIcon>}</div>
          {isSmall ? match.team2.clubCode : (isShort ? match.team2.shortName : match.team2.name)}
        </div>
      </div>
      <div className="ScoreContainer">
        <div className="Scores">
          <div className="Score">
            {setPoints.team1}
          </div>
          <div className="Score">
            {setPoints.team2}
          </div>
        </div>
        <div className="Scores">
          <div className="Score">
            {setScore.team1}
          </div>
          <div className="Score">
            {setScore.team2}
          </div>
        </div>
      </div>
    </div>
})
