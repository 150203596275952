import * as React from 'react';

import './LiveTicker.scss'
import { EventEntry } from './EventEntry';
import { DetailedTickerMatchState, TickerMatch } from '../../../models';

interface Props {
  match: TickerMatch
  matchState: DetailedTickerMatchState
  updateHeight: any
}

export const LiveTicker = (props: Props) => {

    const { matchState, updateHeight } = props
    React.useEffect(() => {
      updateHeight && updateHeight()
    }, [matchState.eventHistory]);

    const eventHistory = matchState.eventHistory
    return <div className="LiveTicker">
      {eventHistory.map((e, i) => <div key={i}><EventEntry key={e.uuid} event={e} {...props} /></div>)}
    </div>

}
