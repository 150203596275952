import * as React from 'react';

import './MatchStats.scss'
import { TickerMatch, TickerMatchSeries, TickerMatchState, TickerMatchStats } from '../../models';
import { useTable, useSortBy } from 'react-table';
import { TickerApiPlayerStats } from '../../../../shared/models/ticker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortUp, faSortDown, faSort } from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as PhoneToHorizontal } from '../../images/ticker-icons/phone-to-horizontal.svg';
import { useWindowSize } from '../../utils';

interface Props {
  matchStats: TickerMatchStats
  match: TickerMatch
  matchState: TickerMatchState
  matchSeries: TickerMatchSeries
}

const pointSum = (p: TickerApiPlayerStats) => p.serve.ace + p.spike.points + p.block.total
const pointErr = (p: TickerApiPlayerStats) => p.serve.error + p.receive.error + p.spike.error
const receivePercentage = (p: TickerApiPlayerStats) => +(p.receive.perfect / p.receive.total * 100).toFixed(2) || '•'
const spikePercentage = (p: TickerApiPlayerStats) => +((p.spike.points / p.spike.total) * 100).toFixed(2) || '•'
const pointDiff = (p: TickerApiPlayerStats) => pointSum(p) - pointErr(p)
const lastName = (p: TickerApiPlayerStats) => p.lastName + (p.isLibero ? ' (L)' : '')

const StatsTable = (props: { data: TickerApiPlayerStats[] }) => {

  const size = useWindowSize();
  const minimalTable = size.width < 568;
  const shortTable = size.width < 812;

  const columns: any = React.useMemo(
    () => {
      const firstNameCol = {
        Header: 'Vorname',
        Footer: 'Mannschaft',
        id: 'firstName',
        accessor: (p: TickerApiPlayerStats) => p.firstName
      };
      const lastNameCol = {
        Header: 'Nachname',
        Footer: 'Ges.',
        id: 'lastName',
        accessor: lastName
      };
      const jerseyNumberCol = {
        Header: 'N',
        id: 'jerseyNumber',
        accessor: (p: TickerApiPlayerStats) => p.jerseyNumber
      };

      const pointSumCol = {
        Header: 'Sum',
        id: 'pointSum',
        accessor: pointSum,
        Footer: (info: any) => React.useMemo(() => info.rows.reduce((sum: any, row: any) => row.values.pointSum + sum, 0), [info.rows])
      };
      const pointsTotalCol = {
        Header: 'BP',
        id: 'pointsTotal',
        accessor: (p: TickerApiPlayerStats) => p.points.total,
        Footer: (info: any) => React.useMemo(() => info.rows.reduce((sum: any, row: any) => row.values.pointsTotal + sum, 0), [info.rows])
      };
      const pointDiffCol = {
        Header: 'G-V',
        id: 'pointDiff',
        accessor: pointDiff,
        Footer: (info: any) => React.useMemo(() => info.rows.reduce((sum: any, row: any) => row.values.pointDiff + sum, 0), [info.rows])
      };

      const serveTotalCol = {
        Header: 'Sum',
        id: 'serveTotal',
        accessor: (p: TickerApiPlayerStats) => p.serve.total,
        Footer: (info: any) => React.useMemo(() => info.rows.reduce((sum: any, row: any) => row.values.serveTotal + sum, 0), [info.rows])
      }
      const serveErrorCol = {
        Header: 'Fhl',
        id: 'serveError',
        accessor: (p: TickerApiPlayerStats) => p.serve.error,
        Footer: (info: any) => React.useMemo(() => info.rows.reduce((sum: any, row: any) => row.values.serveError + sum, 0), [info.rows])
      };
      const serveAceCol = {
        Header: 'Pkt',
        id: 'serveAce',
        accessor: (p: TickerApiPlayerStats) => p.serve.ace,
        Footer: (info: any) => React.useMemo(() => info.rows.reduce((sum: any, row: any) => row.values.serveAce + sum, 0), [info.rows])
      };

      const receiveTotalCol = {
        Header: 'Sum',
        id: 'receiveTotal',
        accessor: (p: TickerApiPlayerStats) => p.receive.total,
        Footer: (info: any) => React.useMemo(() => info.rows.reduce((sum: any, row: any) => row.values.receiveTotal + sum, 0), [info.rows])
      };
      const receiveErrorCol = {
        Header: 'Fhl',
        id: 'receiveError',
        accessor: (p: TickerApiPlayerStats) => p.receive.error,
        Footer: (info: any) => React.useMemo(() => info.rows.reduce((sum: any, row: any) => row.values.receiveError + sum, 0), [info.rows])
      };
      const receivePercentageCol = {
        Header: 'Prf.%',
        id: 'receivePercentage',
        accessor: receivePercentage,
        Footer: (info: any) => React.useMemo(() => {
          return (props.data.reduce((sum, p) => p.receive.perfect + sum, 0)/info.rows.reduce((sum: any, row: any) => row.values.receiveTotal + sum, 0)*100).toFixed(2)
        }, [info.rows])
      };


      const spikeTotalCol = {
        Header: 'Sum',
        id: 'spikeTotal',
        accessor: (p: TickerApiPlayerStats) => p.spike.total,
        Footer: (info: any) => React.useMemo(() => info.rows.reduce((sum: any, row: any) => row.values.spikeTotal + sum, 0), [info.rows])
      };
      const spikeErrorCol = {
        Header: 'Fhl',
        id: 'spikeError',
        accessor: (p: TickerApiPlayerStats) => p.spike.error,
        Footer: (info: any) => React.useMemo(() => info.rows.reduce((sum: any, row: any) => row.values.spikeError + sum, 0), [info.rows])
      };
      const spikeBlockedCol = {
        Header: 'Blo',
        id: 'spikeBlocked',
        accessor: (p: TickerApiPlayerStats) => p.spike.blocked,
        Footer: (info: any) => React.useMemo(() => info.rows.reduce((sum: any, row: any) => row.values.spikeBlocked + sum, 0), [info.rows])
      };
      const spikePointsCol = {
        Header: 'Pkt',
        id: 'spikePoints',
        accessor: (p: TickerApiPlayerStats) => p.spike.points,
        Footer: (info: any) => React.useMemo(() => info.rows.reduce((sum: any, row: any) => row.values.spikePoints + sum, 0), [info.rows])
      };
      const spikePercentageCol = {
        Header: 'Pkt %',
        id: 'spikePercentage',
        accessor: spikePercentage,
        Footer: (info: any) => React.useMemo(() => {
          return (info.rows.reduce((sum: any, row: any) => row.values.spikePoints + sum, 0)/info.rows.reduce((sum: any, row: any) => row.values.spikeTotal + sum, 0)*100).toFixed(2)
        }, [info.rows])
      };


      const blockTotalCol = {
        Header: 'Pkt',
        id: 'blockTotal',
        accessor: (p: TickerApiPlayerStats) => p.block.total,
        Footer: (info: any) => React.useMemo(() => info.rows.reduce((sum: any, row: any) => row.values.blockTotal + sum, 0), [info.rows])
      };

      const num = {
        Header: minimalTable ? <PhoneToHorizontal fill='white' title={''} />: '',
        id: 'num',
        Footer: '',
        columns: [jerseyNumberCol]
      };
      const name = {
        id: 'name',
        Header: 'Name',
        Footer: '',
        columns: (minimalTable || shortTable) ? [lastNameCol] : [lastNameCol, firstNameCol]
      };
      const points = {
        id: 'points',
        Header: minimalTable ? 'Pkt' : 'Punkte',
        Footer: 'Punkte',
        columns: minimalTable ? [pointSumCol] : [pointSumCol, pointsTotalCol, pointDiffCol]
      };
      const serve = {
        id: 'serve',
        Header: minimalTable ? 'Auf' : 'Aufschlag',
        Footer: 'Aufschlag',
        columns: minimalTable ? [] : [serveTotalCol, serveErrorCol, serveAceCol]
      };
      const receive = {
        id: 'receive',
        Header: 'Annahme',
        Footer: 'Annahme',
        className: 'hide-mobile',
        columns: [receiveTotalCol, receiveErrorCol, receivePercentageCol]
      };
      const spike = {
        id: 'spike',
        Header: minimalTable ? 'Ang' : 'Angriff',
        Footer: 'Angriff',
        columns: minimalTable ? [spikePointsCol] : [spikeTotalCol, spikeErrorCol, spikeBlockedCol, spikePointsCol, spikePercentageCol]
      };
      const block = {
        id: 'block',
        Header: minimalTable ? 'Blk' : 'Block',
        Footer: 'Block',
        columns: [blockTotalCol]
      };

      if (minimalTable) {
        return [num, name, points, serve,  spike, block]
      }

      return [num, name, points, serve, receive,  spike, block]

    }, [minimalTable, shortTable, props.data]
  )

  const tableInstance = useTable({
    columns,
    data: props.data
  }, useSortBy)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = tableInstance

  return <div className="stats-container">
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, idx) => (
          <tr {...headerGroup.getHeaderGroupProps() } key={'head-row-' + idx}>
            { headerGroup.headers.map((column: any, cidx) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())} key={'head-row-' + idx + '-col-' + cidx}>
                { column.render('Header')}
                { column.isSorted ? (column.isSortedDesc ? <FontAwesomeIcon icon={faSortDown} /> : <FontAwesomeIcon icon={faSortUp} />) : (!column.columns ? <FontAwesomeIcon icon={faSort} /> : <span></span>) }
              </th>
            ))
            }
          </tr>))
        }
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row, idx) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()} key={'body-row-' + idx}>
              {row.cells.map((cell, cidx) => {
                return (
                  <td {...cell.getCellProps()} key={'body-col-' + idx + '-col-' + cidx}>
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
      <tfoot>
        {footerGroups.map((group, idx) => (
          <tr {...group.getFooterGroupProps()} key={'foot-row-' + idx}>
            {group.headers.map((column, colidx) => (
              <td {...column.getFooterProps()} key={'foot-row-' + idx + '-col-' + colidx}>
                {column.render('Footer')}
              </td>
            ))}
          </tr>
        ))}
      </tfoot>
    </table>
  </div>
}

const MatchStats = (props: Props) => {
  const { matchStats } = props

  return matchStats ? <div className="MatchStats">
    <h2>Heim</h2>
    <StatsTable data={matchStats.home.playerStats}></StatsTable>
    <h2>Auswärts</h2>
    <StatsTable data={matchStats.guest.playerStats}></StatsTable>
  </div> : <div className="MatchStats"><h1>Keine Statistiken vorhanden</h1></div>
}

export default MatchStats
